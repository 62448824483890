import { useCallback, memo, FC } from "react";
import { TextField } from "@mui/material";
import { StyleRules } from "@mui/styles";
import withStyles from "@mui/styles/withStyles";
import createValidationError from "../../../shared/utility/createValidationError";
import GridFormField, { GridFormFieldProps } from "./GridFormField";
import { ValidationErrors } from "@shipworks/hub-utility/dist/validator/validatorTypes";

const styles = (theme): StyleRules => ({
  textField: {
    "& input": {
      backgroundColor: theme.palette.common.white,
    },
  },
});

export interface GridFormInputFieldProps extends GridFormFieldProps {
  id: string;
  name: string;
  validationErrors?: (string | ValidationErrors)[];
  value: string | undefined;
  disabled?: boolean;
  onChange?(propName: string, value: string): void;
  InputProps?: any;
  type?: string;
}

const GridFormInputField: FC<GridFormInputFieldProps> = ({
  title,
  subTitle,
  breakpoints,
  className,
  name,
  onChange = () => {},
  validationErrors,
  value,
  classes,
  ...rest
}) => {
  // @ts-ignore - this function is not setup to use the validation type
  const validationError = createValidationError(validationErrors, title);

  const changeHandler = useCallback(
    (e) => {
      onChange(name, e.target.value);
    },
    [name, onChange],
  );

  return (
    <GridFormField
      breakpoints={breakpoints}
      className={className}
      title={title}
      subTitle={subTitle}
    >
      <TextField
        {...rest}
        size="small"
        error={!!validationErrors}
        helperText={validationError}
        value={value || ""}
        onChange={changeHandler}
        className={classes.textField}
        FormHelperTextProps={{
          // @ts-ignore
          component: "div",
        }}
      />
    </GridFormField>
  );
};

export default withStyles(styles)(memo(GridFormInputField));
