import { memo } from "react";
import { countries } from "@shipworks/hub-utility/dist/countries";
import { GridFormComboBoxField } from "./index";

interface GridFormCountrySelectProps {
  id: string;
  title: string;
  breakpoints: any;
  className?: any;
  onChange(selectedValue): void;
  value: any;
  validationErrors: any;
  disabled?: boolean;
  displayAbbreviation?: boolean;
}

const GridFormCountrySelect = ({
  id,
  title,
  breakpoints,
  className,
  onChange,
  value,
  validationErrors,
  disabled = false,
  displayAbbreviation = false,
}: GridFormCountrySelectProps) => (
  <GridFormComboBoxField
    id={id}
    title={title}
    breakpoints={breakpoints}
    className={className}
    onChange={onChange}
    value={value}
    options={countries}
    validationErrors={validationErrors}
    labelField={displayAbbreviation ? "code" : "name"}
    valueField="code"
    name="Country"
    disabled={disabled}
  />
);

export default memo(GridFormCountrySelect);
